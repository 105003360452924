import React from 'react';
import step1Img from '../images/dqe_assets/DQE-assets-progressbar-p02@2x.png';
import nextButton from '../images/dqe_assets/DQE-assets-next-arrow@2x.png';
export default class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.goForward = this.goForward.bind(this);
  }

  goForward(){
    const name = document.querySelector("#name");
    const company = document.querySelector("#company")
    const email = document.querySelector("#email")
    if (!name.value) name.classList.add('invalid');
    if (!company.value) company.classList.add('invalid');
    if (!email.value || !this.validateEmail(email.value)) email.classList.add('invalid');
    if (name.value && company.value && email.value && this.validateEmail(email.value)){
      this.props.update({
        page: 2,
        name: name.value,
        company: company.value,
        email: email.value
      },() => {this.props.stepOne()});

    }
  }


  checkField(event) {
    let nameVal = event.target.value;
    const element = document.querySelector(`#${event.target.id}`);
    element.classList.remove('invalid');
    let capsName = '';
    nameVal = nameVal.split(' ');
    nameVal.forEach(e => {
      capsName = `${capsName}${e.charAt(0).toUpperCase()}${e.slice(1)} `;
    });
    if (capsName.charAt(capsName.length - 1) === ' ') {
      let temp = capsName.slice(0,-1);
      capsName = temp;
    };
    event.target.value = capsName;
  }

  validateEmail(email) {
    // eslint-disable-next-line
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
    if (email.match(mailformat)) {
      return true;
    }
    else {
      return false;
    }
  }

  render(){
    return (
      <div className='container d-flex flex-column justify-content-center align-items-center calc-center'>
        <div className="row">
          <div className="col-12 description-text">
            <p>
            This ROI calculator is designed to help you estimate the <span className="bold">hidden costs</span>  your
            company incurs as a result of <span className="bold">poor data quality</span> . Fill out the form below to get
            an estimate of the potential benefits your organization can achieve by
            <span className="bold"> improving the state of your data</span>.
          </p>
          </div>

        </div>
        <div className="row">
          <p className="title"><span className="blue">STEP 1:</span> YOU</p>
        </div>
        <div className="row justify-content-center">
          <div className="step-div">
            <img className="pb-5" src={step1Img} alt="Step 1"></img>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form className="">
              {/* <div className="container"> */}
                <input id='name' className="custom-text-input mb-2" type="text" placeholder="Name" required defaultValue={this.props.values.name} onBlur={this.checkField}></input>
                <input id='company' className="custom-text-input mb-2" type="text" placeholder="Company" required defaultValue={this.props.values.company} onBlur={this.checkField}></input>
                <input id='email' className="custom-text-input mb-2" type="text" placeholder="Email" required defaultValue={this.props.values.email}></input>
              {/* </div> */}
              <div className="row justify-content-center pt-3 nav-buttons">
                <div>
                  <img id="next-button" className="next-button" src={nextButton} alt="Next" onClick={this.goForward}></img>
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>
    )
  }
}
