import React from 'react';
import step2Img from '../images/dqe_assets/DQE-assets-progressbar-p02_1@2x.png';
import nextButton from '../images/dqe_assets/DQE-assets-next-arrow@2x.png';
export default class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.goForward = this.goForward.bind(this);
    this.goBack= this.goBack.bind(this);
  }

  /* componentDidMount() {
    document.addEventListener('keypress', e => {
      if (e.keyCode === 13) {
        this.goForward();
      }
    })
  } */

  goForward(event){
    event.preventDefault();
    const revenue = document.querySelector("#revenue");
    const customers = document.querySelector("#customers");
    const dataQuality = document.querySelector("#dataQuality");
    const businessType = document.querySelector("#businessType");
    if (!revenue.value) revenue.classList.add('invalid');
    if (!customers.value) customers.classList.add('invalid');
    if (!dataQuality.value) dataQuality.classList.add('invalid');
    if (!businessType.value) businessType.classList.add('invalid');
    if (revenue.value && customers.value && dataQuality.value && businessType.value) {
      this.props.update({
        page: 3,
        revenue: revenue.value.replace(/[^0-9]/g, ''),
        customers: customers.value.replace(/[^0-9]/g, ''),
        businessType: businessType.value,
        dataQuality: dataQuality.value
      });
    }

  }

  goBack(){
    const revenue = document.querySelector("#revenue").value;
    const customers = document.querySelector("#customers").value;
    const dataQuality = document.querySelector("#dataQuality").value;
    this.props.update({
      page: 1,
      revenue: revenue.replace(/[^0-9]/g, ''),
      customers: customers.replace(/[^0-9]/g, ''),
      dataQuality: dataQuality
    });
  }

  checkField(event){
    const field = event.target.id;
    const nfObject = new Intl.NumberFormat('en-US');
    switch (field) {
      case 'revenue':
        let revVal = event.target.value;
        revVal = revVal.replace(/[^0-9]/g, '');
        if (!isNaN(revVal)){
          revVal = nfObject.format(revVal);
          revVal = '$' + revVal;
          event.target.value = revVal;
        }
        event.target.value = revVal;
        if (revVal === '$' || revVal === '' || revVal ==='$0') {
          event.target.value = '';
        }
        break;
      case 'customers':
        let cusVal = event.target.value;
        cusVal = cusVal.replace(/[^0-9]/g, '');
        if (!isNaN(cusVal)){
          cusVal = nfObject.format(cusVal);
        }
        event.target.value = cusVal;
        if (cusVal === '0' || cusVal === ''){
          event.target.value = '';
        }
        break;
      default:
        break;

    }
  }

  render() {
    return (
      <div className='container d-flex flex-column justify-content-center align-items-center calc-center'>
        <div className="row">
          <p className="title"><span className="blue">STEP 2:</span> YOUR COMPANY</p>
        </div>
        <div className="row justify-content-centerstep-div">
          <div className="step-div">
            <img className="pb-5" src={step2Img} alt="Step 2"></img>
          </div>

        </div>
        <div className="row">
          <div className="col-12">
            <form className="">
              {/* <div className="container"> */}
                <input id='revenue' className="custom-text-input mb-2" type="text" placeholder="Annual Revenue" required defaultValue={this.props.values.revenue} onChange={this.checkField}></input>
                <input id='customers' className="custom-text-input mb-2" type="text" placeholder="Number Of Customers" required defaultValue={this.props.values.customers} onChange={this.checkField}></input>
                <select id='businessType' className="custom-text-input mb-2 dropdown" type="text" placeholder="Estimated Data Quality" required defaultValue={this.props.values.businessType}>
                  <option value="">Business Type</option>
                  <option value="B2B">B2B</option>
                  <option value="B2C">B2C</option>
                  {/* <option value="B2M">B2M</option> */}
                </select>
                <select id='dataQuality' className="custom-text-input mb-2 dropdown" type="text" placeholder="Estimated Data Quality" required defaultValue={this.props.values.dataQuality}>
                  <option value="">Current Data Quality</option>
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </select>

              {/* </div> */}
              <div className="row mt-3 justify-content-center nav-buttons">
                <div className="col-6">
                  <img id="back-button" className="back-button" src={nextButton} alt="Next" onClick={this.goBack}></img>
                </div>
                <div className="connect-button col-6">
                  <a href="https://www.cognistx.com" onClick={this.goForward} className=" align-middle pl-1 pr-1">Calculate ROI</a>
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>
    )
  }
}
