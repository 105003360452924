import React from 'react';
import step3Img from '../images/dqe_assets/DQE-assets-progressbar-p03@2x.png';
import nextButton from '../images/dqe_assets/DQE-assets-next-arrow@2x.png';
// import nextButtonHover from '../images/dqe_assets/DQE-assets-next-arrow-hover@2x.png';
export default class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.goBack = this.goBack.bind(this);
  }
  goBack(){
    this.props.update(this.props.update({
      page: 2,
      potential_benifits: null,
      indirect_costs: null
      }));
  }
  componentDidMount(){
    this.props.results();
  }
  componentDidUpdate(prevProps){
    if (this.props.values.potential_benifits !== prevProps.values.potential_benifits) {
      this.setState(this.props.values);
    }
    else {
      return;
    }

  }
  preRender(){
    return (
      <div className='container d-flex flex-column justify-content-center align-items-center calc-center'>
        <div className="row">
          <p className="title"><span className="blue">STEP 3:</span> DATA QUALITY ROI</p>
        </div>
        <div className="row justify-content-center col-lg-6 col-sm-3">
          <img className="img-fluid pb-5" src={step3Img} alt="Step 1"></img>
        </div>
        <div className="row w-100">
          <div className="col-12">
            <div className="results row">
              <div className="row">
                <div className="col-6"><div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div></div>
                <div className="col-6"><div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div></div>
              </div>
              <div className="row">
                <div className="col-6"><p className='text-center'>Estimated Benefit</p></div>
                <div className="col-6"><p className='text-center'>Estimated Indirect Cost</p></div>
              </div>
              {/* <div className="row">
                <div className="col-6">Estimated Time to Prepare</div>
                <div className="col-6">Estimated Time To Build</div>
              </div>
              <div className="row">
                <div className="col-6"><p className='text-center'>X MONTHS</p></div>
                <div className="col-6"><p className='text-center'>X MONTHS</p></div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="row w-100 justify-content-center mt-5 pt-3 nav-buttons d-flex flex-row">
          <div className="col-6 justify-content-end">
            <img id="back-button" className="back-button" src={nextButton} alt="Next" onClick={this.goBack}></img>
          </div>
          <div className="col-6">
            <a href="https://www.cognistx.com" className="connect-button">Connect with a data scientist</a>
          </div>
        </div>
      </div>
    )
  }
  dataRender(){
    let connectLink = `https://www.cognistx.com/dqe-connect?name=${this.props.values.name}&email=${this.props.values.email}&company=${this.props.values.company}`;
    let benifits = parseFloat(this.state.potential_benifits.toString().replace(/^$,+/i, ''));
    benifits = Math.round(benifits);
    let indirect = parseFloat(this.state.indirect_costs.toString().replace(/^$,+/i, ''));
    indirect = Math.round(indirect);
    const nfObject = new Intl.NumberFormat('en-US');
    benifits = nfObject.format(benifits);
    benifits = '$' + benifits;
    indirect = nfObject.format(indirect);
    indirect = '$' + indirect;
    return (
      <div className='container d-flex flex-column justify-content-center align-items-center calc-center '>
        <div className="row">
          <p className="title"><span className="blue">STEP 3:</span> DATA QUALITY ROI</p>
        </div>
        <div className="row justify-content-center">
          <div className="step-div">
            <img className="pb-5" src={step3Img} alt="Step 3"></img>
          </div>
        </div>
        <div className="row w-100 results-container">
          <div className="col-12">
            <div className="results justify-content-center">
              <div className="calculated-div">
                <div className="row">
                  <div className="col-6"><p className='text-center price-result'><span className="blue">{benifits}</span></p></div>
                  <div className="col-6"><p className='text-center price-result'><span className="blue">{indirect}</span></p></div>
                </div>
                <div className="row">
                  <div className="col-6 pb-4"><p className='text-center'>Estimated Benefit</p></div>
                  <div className="col-6 pb-4"><p className='text-center'>Estimated Indirect Cost</p></div>
                </div>
                  <div className="row">
                        <div className="col-6 text-center">
                      <p>This is the estimated aggregate of potential benefits
                      your organization can achieve by improving the state
                      of your data quality.</p>
                      </div>
                    <div className="col-6 text-center">
                      <p>This number represents the potential downstream
                      impact of poor quality data, such as administrative
                      downtime or inaccurate projections.</p>
                    </div>
                  </div>

              </div>
            </div>
          </div>
        </div>
        <div className="row w-100 justify-content-center pt-3 nav-buttons d-flex flex-row">
          <div>
            <img id="back-button" className="back-button" src={nextButton} alt="Next" onClick={this.goBack}></img>
          </div>
          <div className="connect-button">
            <a href={connectLink} className=" align-middle pl-1 pr-1">Connect with a data scientist</a>
          </div>
        </div>
      </div>
    )
  }
  render() {
    if (!this.state.potential_benifits) {
      return this.preRender();
    }
    else {
      return this.dataRender();
    }
  }
}
