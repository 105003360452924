import React from 'react';
import header from '../images/dqe_assets/DQE-assets-logo-header@2x.png';
import Step1 from './Step1.jsx';
import Step2 from './Step2.jsx';
import Step3 from './Step3.jsx';
require('dotenv').config();
export default class App extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      page:1,
      name:'',
      company:'',
      email:'',
      revenue:null,
      customers:null,
      dataQuality:null,
      potential_benifits:null,
      indirect_costs:null,
      id:null,
      businessType:'',
      link:null
    };
    this.updateData = this.updateData.bind(this);
    this.getResults = this.getResults.bind(this);
    this.postToServer = this.postToServer.bind(this);
    this.postStepOne = this.postStepOne.bind(this);
  }

  componentDidMount(){
    const prodLink = `https://dqe.cognistx.com:${process.env.REACT_APP_SSL_PORT}`;
    const localLink = `http://localhost:${process.env.REACT_APP_PORT}`;

    switch (process.env.REACT_APP_BUILD) {
      case 'prod':
        this.setState({link:prodLink});
        break;
      case 'dev':
      default:
        this.setState({ link: localLink });
        break;
    }

  }
  updateData(data,callback){
    this.setState(data,callback);
  }

  getResults(){
    // gets all data from the server to do a calculation
    const link = `${this.state.link}/all?quality=${this.state.dataQuality}&revenue=${this.state.revenue.replace(/[^0-9]/g, '')}&customers=${this.state.customers.replace(/[^0-9]/g, '')}&business_type=${this.state.businessType}`
    fetch(link)
      .then(response => response.json())
      .then(data => {
        if (data.type==="error"){
          return;
        }
        let newState = {
          potential_benifits: data.benifit_calculation,
          indirect_costs: data.indirect_calculation
        }
        this.setState(newState ,this.postToServer);
      })
      .catch((error) => {
      });
  }

  postStepOne(){
    const opts = {
      "name": this.state.name,
      "company": this.state.company,
      "email": this.state.email,
      "api_key": 'JZnKKnzZdIjV9Lvnw7JW'
    };
    fetch(`${this.state.link}/stepone`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'post',
      body: JSON.stringify(opts)
    })
      .then(res =>
        res.json()
      )
      .then(data => {
        this.setState({id:data.id})
      })
      .catch(err => {

      });
  }

  postToServer(){
    const opts = {
      "name": this.state.name,
      "company": this.state.company,
      "email": this.state.email,
      "revenue": parseFloat(this.state.revenue)
      ,
      "customers": parseFloat(this.state.customers),
      "data_quality": this.state.dataQuality,
      "potential_benifits": parseFloat(this.state.potential_benifits),
      "indirect_costs": parseFloat(this.state.indirect_costs),
      "id":this.state.id,
      "business_type":this.state.businessType,
      "api_key":'JZnKKnzZdIjV9Lvnw7JW'
    };
    if (opts.business_type === "B2B") {

    }

    fetch(`${this.state.link}/steptwo`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'post',
      body: JSON.stringify(opts)
    })
      .then(res =>
        res.json()
      )
      .then(data => {

      })
      .catch(err => {

      });
  }

  onClick(){
    window.location.href = "https://www.cognistx.com";
  }

  render(){
    let displayPage;
    switch (this.state.page) {
      case 2:
        displayPage = (<Step2 values={this.state} update={this.updateData} />)
        break;
      case 3:
        displayPage = (<Step3 results={this.getResults}values={this.state} update={this.updateData} />)
        break;
      default:
        displayPage = (<Step1 stepOne={this.postStepOne} values={this.state} update={this.updateData} />)
        break;
    }
    return (
    <div className="justify-content-center align-items-center app">
      <div className="row">
        <div className="col-12 logo-image hover" onClick={this.onClick}>
          <img className="" src={header} alt="Header"></img>
        </div>
      </div>
      <div className="row">
        <div className="col-12 pt-5 pb-5 mb-5">
          {displayPage}
        </div>
      </div>

    </div>
  );
  }
}
